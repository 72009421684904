
.mainContainer {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: 85vh;
}

.tabContainer{
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 5vw;
  margin-bottom: 1vh;
  gap: 2vw;
  font-size: 1rem;
  width: 100vw;
}

.separator{
  margin-left: 1vw;
  width: 98vw;
  border-top: 1px solid black;
}

.selectedTab{
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  background: linear-gradient(45deg, #217efc, #1b55b5);
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
}

.unselectedTab{
  cursor: pointer;
}

.loading{
  display: flex;
  margin-bottom: 15vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  max-height: 85vh;
}
