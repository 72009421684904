.mainContainer{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 60vh;
  margin-left: 1vw;
}

.emptyCard{
  width: 11vw; 
}

.track{
  display: flex;
  flex-direction: row;
}

.title{
  font-size: 1.25rem;
  margin-top: 2vh;
  margin-bottom: 3vh;
  font-weight: bold;
}