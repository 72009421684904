.MainCaso {
  display: flex;
  width: 99%;
  height: auto;
  background-color: #f2f2f2;
  text-align: left;
  flex-direction: column;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  margin-top: 1vh;
  cursor: pointer;
  padding-bottom: 1rem;
}

.MainCaso:hover {
  border: solid 2px gray;
}

.CasoEtiqueta {
  background-color: #2cbe78;
  color: white;
  display: inline-block;
  margin-left: auto;
  margin-right: 0;
  padding: 4px;
}

.CasoDatos {
  padding: 0 0.5vw;
}

.item {
  margin: 0.5vh 0 0 0;
}

.CasoAyuda {
  display: flex;
  flex-direction: row;
  height: 1.5rem;
  color: #919191;
  margin-top: 0.5rem;
  margin-left: 1vw;
  align-items: center;
}

.CasoReloj {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1vw;
}
