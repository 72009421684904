:root {
  --scrollbar-color-ingreso: #a544d9;
  --scrollbar-color-premediacion: #2cbe78;
}

.panelIngreso {
  background-color: #ede6f7;
  height: 77.5vh;
}

.agregarCaso {
  text-align: center;
  margin: 2vh 0;
  height: 8vh;
  border-radius: 10px;
  line-height: 7vh; /* Mitad del tamaño más el margen */
  width: 70%;
  background-color: #f2f2f2;
  cursor: pointer;
}

.agregarCaso:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.panelPremediacion {
  background-color: #dcece4;
  height: 77.5vh;
}

.panelMediacion {
  background-color: #f5e3d3;
  height: 77.5vh;
}

.panelSeguimiento {
  background-color: #f6eeda;
  height: 77.5vh;
}

.panelCierre {
  background-color: #e8f0fa;
  height: 77.5vh;
}

.panelContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dddbdb;
  margin: 2.5vh 0.5vw;
  max-height: 72vh;
  max-width: 19vw;
  min-height: 9vh;
  border-radius: 8px;
  padding-bottom: 1rem;
}

.titleCard {
  width: 90%;
  top: 2vh;
  margin-bottom: 1vh;
  margin-left: 5%;
  background-color: #4d4d4d;
  color: white;
  text-align: left;
  height: 5vh;
  border-radius: 10px;
  position: relative;
}

.title {
  margin-left: 2vw;
  font-size: 2vh;
  line-height: 1vh;
  font-weight: bold;
}

.notification {
  background-color: red;
  color: white;
  font-size: 0.8rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -35%);
}

.cardScroller {
  margin: 1.5vh 0 0 0.5vw;
  width: 90%;
  max-height: 65vh;
  overflow: auto;
}

.cardScroller::-webkit-scrollbar {
  border-radius: 12px;
  width: 10px; 
}

/* Cambiamos el color del scrollbar y del track (fondo del scrollbar) */
.cardScroller[tipo-caso="panelIngreso"]::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #a544d9;
}

.cardScroller[tipo-caso="panelPremediacion"]::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #2cbe78;
}

.cardScroller::-webkit-scrollbar-track {
  border-radius: 16px;
  background-color: #f2f2f2;
}
