.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(8px);
  z-index: 5;
  height: 100vh;
  width: 100vw;
}

.modal {
  cursor: initial;
  position: fixed;
  left: 10vw;
  top: 5vh;
  height: 90vh;
  width: 80vw;
  border-radius: 12px;
  z-index: 10;
  background-color: #f2f2f2;
  overflow-y: scroll;
  overflow-x: hidden;
}

.closeModal {
  cursor: pointer;
  text-align: center;
  font-size: 2vw;
  font-weight: bolder;
  line-height: 4vw;
  position: fixed;
  right: 6vw;
  top: 3vh;
  height: 4vw;
}

.closeModal:hover {
  font-size: 2.5vw;
  height: 4.25vw;
}

/* #contenido_modal_cierre::-webkit-scrollbar {
  margin-top: 3vh;
  margin-bottom: 3vh;
  border-radius: 12px;
  width: 16px;
}
#contenido_modal_cierre::-webkit-scrollbar-thumb {
  margin-top: 3vh;
  margin-bottom: 3vh;
  background: #888;
  border-radius: 12px;
} 
#contenido_modal_cierre::-webkit-scrollbar-track {
  margin-top: 3vh;
  margin-bottom: 3vh;
  background: #f1f1f1;
  border-radius: 12px;
}  */
