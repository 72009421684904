/* TODO agregar "backdrop-filter: blur(8px);" en el div principal del modal*/

.container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
}

.encabezado {
  padding-top: 1rem;
  font-size: 24px;
  font-weight: bolder;
  padding-left: 5rem;
  padding-bottom: 1rem;
  background-color: #4d4d4d;
  color: white;
  margin-bottom: 1rem;
}

/* .border1 {
  border-left: 1rem solid #fdc204;
}
.border2 {
  border-left: 1rem solid #ff636e;
}
.border3 {
  border-left: 1rem solid #2bc26e;
}
.border4 {
  border-left: 1rem solid #2377f8;
} */

.titulo {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
}

.dosPartes {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.subtitulo {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 0.5rem;
  max-width: 40vw;
}

.actionBox {
  width: 15vw;
}

.textArea {
  width: 34vw;
}

.selectOne {
  display: flex;
  flex-direction: column;
}

.checkWithOption {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.boton {
  margin-top: 2vh;
  margin-bottom: 5vh;
  line-height: 5vh;
  height: 5vh;
  width: 10vw;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #a144d9;
}

.boton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  cursor: pointer;
}

.addDeleteRow {
  display: flex;
  flex-direction: row;
}

.gridPartes {
  display: grid;
  grid-template-rows: 5vh 5vh 5vh;
  grid-template-columns: 10vw 5vw 3vw 5vw;
  align-items: center;
}

.mas {
  background-color: #2bc26e;
  height: 4vh;
  width: 4vh;
  line-height: 4vh;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
}

.margin_top {
  margin-top: 1rem;
}

.mas:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.menos {
  background-color: #ff636e;
  height: 4vh;
  width: 4vh;
  line-height: 4vh;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
}

.menos:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.collapsibleContainer {
  width: 5vh;
  height: 5vh;
}

.collapsible {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  width: 34vw;
  background-color: #50546f;
  color: white;
  font-size: 16px;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  justify-content: space-between;
}

.arrowClose {
  font-size: 16px;
  font-weight: bold;
  transform: rotate(90deg) scale(1, 1.5);
  right: 1vw;
}

.arrowOpen {
  font-size: 16px;
  font-weight: bold;
  transform: rotate(-90deg) scale(1, 1.5);
  right: 1vw;
}

.saveIcon {
  cursor: pointer;
  text-align: center;
  font-size: 2vw;
  font-weight: bolder;
  line-height: 4vw;
  position: fixed;
  right: 6vw;
  top: 12vh;
  height: 4vw;
  color: white;
}

.save:hover,
.clipIcon:hover {
  font-size: 2.5vw;
}

.saveIcon:hover {
  height: 4.25vw;
}

.attachIcon {
  cursor: pointer;
  text-align: center;
  font-size: 2vw;
  font-weight: bolder;
  line-height: 4vw;
  position: fixed;
  right: 6vw;
  top: 22vh;
  height: 4vw;
  border-radius: 50%;
}

.attachIcon:hover {
  height: 4.25vw;
}

.datos_derivador {
  display: inline-block;
  /* flex-direction: row; */
  gap: 1rem;
  border: 1px solid gray;
  padding: 12px;
  margin-top: 0.5rem;
  border-radius: 8px;
  background-color: #e7eaee;
  color: black;
}

.div_conjunto_derivadores {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  margin-top: 1rem;
}

.gridPartes {
  margin-bottom: 1rem;
}
/* 
.mainContainer {
  display: flex; */

/* } */

/* CSS MODAL GUARDADO */

.guardarModalContainer {
  position: fixed;
  background-color: #ede6f7;
  height: auto;
  width: 40vw;
  top: 50%;
  z-index: 1001;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
}

.encabezadoGuardar {
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 16px;
  font-weight: bolder;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  text-align: center;
  padding-bottom: 1rem;
  background-color: #4d4d4d;
  color: white;
  margin-bottom: 1rem;
}

.botones_modales_verificacion {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
}

.enunciado_modal {
  padding: 0rem 2rem;
  font-size: 14px;
  font-weight: bold;
}

.btn_seguir_en_form {
  margin-top: 2vh;
  /* margin-bottom: 5vh; */
  line-height: 5vh;
  height: auto;
  width: 15vw;
  border: none;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #a544d9;
}

.btn_seguir_en_form:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.btn_guardar_en_form {
  margin-top: 2vh;
  /* margin-bottom: 5vh; */
  line-height: 5vh;
  height: auto;
  width: 15vw;
  border: none;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #2075ff;
}

.btn_guardar_en_form:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.btn_cerrar_form {
  margin-top: 2vh;
  margin-bottom: 5vh;
  line-height: 5vh;
  height: 5vh;
  border: none;
  width: 15vw;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #ff5565;
}

.btn_cerrar_form:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.error {
  border: 2px solid red;
}

.subtitulo_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mensaje_error_datos {
  font-size: 12px;
  color: red;
}

.subtitulo_div p {
  padding: 0;
  margin: 0;
}

.boton_desactivado {
  margin-top: 2vh;
  margin-bottom: 5vh;
  line-height: 5vh;
  height: 5vh;
  width: 10vw;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #a144d9;
  opacity: 0.5;
  pointer-events: none;
}

/* .boton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  cursor: pointer;
} */

.div_ingreso {
  margin-top: 1rem;
}

.textarea_placeholder::placeholder {
  font-style: italic;
}

.input_cursiva::placeholder {
  font-style: italic;
}

.saveIcon_desactivado {
  cursor: pointer;
  text-align: center;
  font-size: 2vw;
  font-weight: bolder;
  line-height: 4vw;
  position: fixed;
  right: 6vw;
  top: 12vh;
  height: 4vw;
  color: white;
  opacity: 0.5;
}

.saveIcon_desactivado:hover {
  height: 4.25vw;
}

.fileInput {
  display: none;
}

.fileInputLabel {
  display: inline-block;
  padding: 8px 16px;
  background-color: #2196f3;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.fileInputLabel:hover {
  background-color: #0e91d0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.fileName {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 4px;
}

.btn_descarga_drawer {
  background-color: #0e91d0;
  border: none;
  border-radius: 12px;
  padding: 8px;
  color: white;
}

.btn_descarga_drawer:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.div_subir_nuevos_documentos {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 1rem;
}

.nuevos_documentos {
  overflow-y: auto;
  max-height: 320px;
}

.columna_subir_nuevo {
  display: flex;
  flex-direction: column;
  width: 12rem;
  justify-content: center;
  margin: 0 auto;
}

.subir_nuevo_btn {
  background-color: #20c997;
  border: none;
  border-radius: 5px;
  padding: 8px;
  color: white;
  margin-top: 8px;
  font-weight: bold;
}

.subir_nuevo_btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}
