.card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 12.5vh;
  width: 11vw;  
  border-radius: 1vh;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.pieChart{
  height: 10vh;
  width: 10vw;
}

.title{
  font-weight: bold;

}

.indicador{
  margin-top: 0.5vh;
}