.mainContainer {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  max-height: 77.5vh;
}

.rowFlex {
  display: flex;
  flex-direction: row;
}

.dosPartes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.divisorFiltros {
  font-weight: bold;
  margin-top: 0.5vh;
  margin-bottom: 1vh;
}

.filtrosColumn {
  margin-left: 1vw;
}

.actionBox {
  font-size: 12px;
  width: 7.5vw;
}

.subtitulo {
  font-size: 12px;
  width: 7.5vw;
}

.grafico {
  display: flex;
  border-radius: 1vh;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-left: 1vw;
  margin-right: 1vw;
  width: 55vw;
  height: 75vh;
}

.loadingGraph {
  display: flex;
  height: 50vh;
  margin-bottom: 15vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  max-height: 85vh;
}

.graphContainer {
  margin-left: 1vw;
  margin-right: 1vw;
  width: 55vw;
  height: 75vh;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  border-radius: 1vh;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-left: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
  height: 12.5vh;
  justify-content: space-between;
  width: 20vw;
  margin-bottom: 1vh;
  font-size: 12px;
}

.date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resumen {
  max-width: 20vw;
  min-width: 20vw;
  max-height: 67.5vh;
  border-radius: 1vh;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-left: 1vw;
  padding: 1vw;
  overflow-y: auto;
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  height: 75vh;
}

.summaryItem {
  display: grid;
  grid-template-columns: 15vw 2vw;
}

.summaryItem hr {
  border: 0.5px dashed;
  width: 17.5vw;
  color: grey;
}

.summaryLineContainer {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}

.summaryColor {
  margin-right: 0.25vw;
  height: 1rem;
  width: 1.5rem;
  border-radius: 5px;
}

.keyValue {
  font-size: 12px;
}

.divider {
  border-style: dashed;
  border-width: 1px;
  width: 100%;
  color: rgb(200, 200, 200);
}

.botonDescarga {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  height: 5vh;
  width: 55vw;
  margin-top: 1vh;
  z-index: 2;
  /* background: linear-gradient(to right, #217efc, #1b55b5); */
}

.botonDescarga2 {
  background: linear-gradient(to right, #217efc, #1b55b5);
}

.botonDescarga img {
  position: absolute;
  cursor: pointer;
  left: 45vw;
  height: 100%;
}
