.formContainer {
  margin-right: 2vw;
}

.ingreso_campos {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #f9f9f9;
  padding-top: 5vh;
  padding-left: 3vw;
  border-radius: 0 8px 0 0;
}

.premediacion_campos {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #f9f9f9;
  padding-top: 5vh;
  padding-left: 3vw;
  border-radius: 0 8px 0 0;
}

.mediacion_campos {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #f9f9f9;
  padding-top: 5vh;
  padding-left: 3vw;
  border-radius: 0 8px 0 0;
}

.seguimiento_campos {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #f9f9f9;
  padding-top: 5vh;
  padding-left: 3vw;
  border-radius: 0 8px 0 0;
}

.cierre_campos {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #f9f9f9;
  padding-top: 5vh;
  padding-left: 3vw;
  border-radius: 0 8px 0 0;
}

.titulo_etapa {
  font-weight: bold;
  margin-bottom: 1rem;
}
/* <div className={classes.div_info_enunciado}>

</div>
<div className={classes.div_info_valor}>

</div> */
.linea_info {
  margin-bottom: 0.5rem;
}

.div_info {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.div_info_enunciado {
  width: 10rem;
  font-weight: bold;
}

.div_info_valor {
  color: grey;
}

.subtitulos {
  text-align: center;
  font-weight: bold;
  margin-top: 1rem;
}
