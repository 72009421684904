.mainContainer{
  display: flex;
  flex-direction: column;
  /* border: 2px solid purple; */
  gap: 8px;
  justify-content: space-evenly;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  /* border-radius: 8px; */
  background-color: #f2f2f2;
  height: auto;
}

.mainContainer:hover{
  border: solid 2px gray;
}

.containerRow{
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
}

.row{
  width: 10rem;
  

}

.row2{
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;

}

.bold{
  font-weight: bold;
}

.value_box{
  background-color: white;
  color: gray;
  padding: 8px;
}