.mainContainer{
  background-color: #F9F9F9;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  padding-top: 3vh;
  padding-left: 5vw;
  border-radius: 0 8px 0 0 ;
  width: 57vw;
  margin-right: 2vw;
}

.sinEncuesta{
  padding-top: 3vh;
  padding-bottom: 3vh;
  font-weight: bold;
}

.titulo{
  font-size: large;
  font-weight: bold;
  padding-top: 3vh;
  padding-bottom: 1.5vh;
}

.separator{
  margin-left: 0;
  width: 47.5vw
}

.description{
  width: 47.5vw;
  padding-bottom: 1.5vh;
}

.option{
  /* padding-top: 1vh; */
  padding-bottom: 2vh;
  display: grid;
  gap: 2vw;
  grid-template-columns: 28vw 15vw;
}

.div_boton{
  margin-top: 1vh;
  margin-bottom: 2vh;
  display: flex;
  justify-content: left;
  align-items: center;
}

.boton_enviar{
  background-color: #4d4d4d;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 8px 12px;
  width: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.boton_enviar:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}