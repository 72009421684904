.pagination_main{  
    padding-bottom: 2rem;
}

.pagination_bar{
display: flex;
flex-direction: row;
gap: 0.75rem;
align-items: center;
justify-content: right;
}

.page_link{
    cursor: pointer;
}

.active_link{
    background-color: black;
    color: white;
}

