.Navbar {
  display: flex;
  height: 15vh;
  width: 100vw;
}

.NavbarHeader {
  display: flex;
  background-color: #f7f7f7;
  align-items: center;
}
/* Logo */
.HeaderLogo {
  text-align: left;
  padding-left: 5vw;
  width: 55vw;
}

.NavbarImage {
  width: auto;
  height: 15vh;
  cursor: pointer;
}

/* Navegadores */

.HeaderNav {
  flex-direction: row-reverse;
  display: flex;
  width: 25vw;
}

.HeaderNavList {
  display: flex;
  width: 25vw;
  height: 7.5vh;
  background-color: #ffffff;
  border-radius: 10px;
  justify-content: space-evenly;
}

.ListItem {
  text-decoration: none;
  align-self: center;
}

.Link {
  text-decoration: none;
  font-size: 1rem;
  color: black;
}

.active {
  background: linear-gradient(45deg, #217efc, #1b55b5);
  padding: 1.5vh;
  border-radius: 10px;
  color: #ffffff;
  font-weight: bolder;
}

.HeaderUser {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 5vw;
  width: 20vw;
}

.UserPanelButton {
  height: 10vh;
  width: 10vh;
  background: linear-gradient(45deg, #217efc, #1b55b5);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.Item-Casos {
  background-color: #2b2b2b;
  color: white;
  font-weight: bold;
}

.UserPanelButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.UserIcon {
  height: 2.25rem;
  width: 2.25rem;
}

.ListItem:hover {
  font-weight: bold;
  cursor: pointer;
}

.loginUserCard {
  position: fixed;
  top: 14vh;
  right: 3rem;
  background-color: white;
  border-radius: 12px;
  padding: 1rem;
  height: 15vh;
  width: 15rem;
  z-index: 5000;
}

.loginUsername {
  background-color: black;
  color: white;
  padding: 5px 35px;
  border-radius: 12px;
  text-align: center;
}

.logout {
  text-align: center;
  cursor: pointer;
}

.logout:hover {
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}
