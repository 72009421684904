.mainContainer {
  display: flex;
  padding-top: 1vh;
  padding-bottom: 1vh;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 1vh;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-left: 1vw;
  margin-bottom: 2vh;
  min-width: 18vw;
  max-width: 18vw;
  transition: rotate 2s ease-in-out;
}

.dosPartes {
  padding-left: 1vw;
  padding-right: 1vw;
  margin-bottom: 0.25vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.actionBox {
  width: 7.5vw;
  font-size: 12px;
}

.subtitulo {
  display: flex;
  font-size: 12px;
  font-weight: bold;
  width: 8vw;
  align-items: center;
}

.collapsible {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #217efc;
  color: white;
  padding: 0.25vw 0.5vw 0.25vw 0.25vw;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  transition: rotate 2s ease-in-out;
}

.divisorFiltros {
  background-color: #217efc;
  color: #ffffff;
  font-weight: bold;
  text-indent: 0.75vw;
  margin-top: 0.25vh;
  margin-bottom: 0.25vh;
}

.arrowClose {
  font-weight: bold;
  transform: rotate(90deg) scale(1, 1.5);
}

.arrowOpen {
  font-weight: bold;
  transform: rotate(-90deg) scale(1, 1.5);
}

.divider {
  border-style: dashed;
  border-width: 1px;
  width: 100%;
  color: rgb(200, 200, 200);
}

.contentContainer {
  transition: max-height 0.5s ease-in-out; /* Transition for max-height */
  overflow: hidden; /* Prevent content from overflowing during animation */

}

.contentContainer .open {
  max-height: 100%;
}

.contentContainer .closed {
  max-height: 0;
}

