.CasosMain {
  background-color: blanchedalmond;
  width: 100vw;
  height: 85vh;
}

.CasosFilterBar {
  height: 7.5vh;
  width: 100vw;
  background-color: gray;
  color: white;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  padding-left: 2rem;
  gap: 6rem;
  font-weight: 500;
}

.CasosPanel {
  display: grid;
  grid-template-columns: 20vw 20vw 20vw 20vw 20vw;
  width: 100vw;
  height: 77.5vh;
}

.cerrarSesion {
  position: absolute;
  right: 1vw;
  width: 10vw;
  height: 5vh;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  font-size: 13px;
}

.cerrarSesion:hover {
  font-size: 14px;
  box-shadow: 1.5px 1.5px 2px 2px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.historicos_main {
  background-color: white;
  justify-content: none;
}

.ingreso_campos {
  border: 1px solid black;
  padding: 0.5rem;
}

.titulo_etapa {
  font-weight: bold;
}

.linea_info {
  margin-bottom: 0.5rem;
}

.filtros_main {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filtros_primer_bloque {
  /* background-color: green; */
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 2fr;
}

.filtros_segundo_bloque {
  /* background-color: yellow; */
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 2fr;
}

.filtros_tercer_bloque {
  /* background-color: orangered; */
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 2fr;
}

.filtros_cuarto_bloque {
  /* background-color: red; */
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cuadro_10_dias {
  background-color: #2cbc81;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 6px;
}

.conteo_10 {
  background-color: #2cbc81;
  color: white;
  padding: 8px;
  border-radius: 1rem;
}

.cuadro_20_dias {
  background-color: #ff7c1b;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 6px;
}

.conteo_20 {
  background-color: #ff7c1b;
  color: white;
  padding: 8px;
  border-radius: 1rem;
}

.cuadro_30_dias {
  background-color: #ff6767;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 6px;
}

.conteo_30 {
  background-color: #ff6767;
  color: white;
  padding: 8px;
  border-radius: 1rem;
}

.cuadro_finalizado {
  background-color: purple;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 6px;
}

.conteo_finalizado {
  background-color: purple;
  color: white;
  padding: 8px;
  border-radius: 1rem;
}

.conteo_casos {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end; /* Cambia "left" a "flex-end" */
  justify-content: flex-end; /* Agrega esta línea para alinear el contenido a la derecha */
  margin-bottom: 0.75rem;
}

.loading{
  position: absolute;
  top: 45vh;
  left: 50vw
}

.btn_filtros_totales{
  background-color: #3166d8;
  color: white;
  display: inline-block;
  float: right;
  padding: 8px;
  border-radius: 1rem;
}

.select_delitos{
  width: 8.5rem;
}

.actionBox{
  width: 12.5vw;
}

.titleAndCheck{
  display: flex;
  flex-direction: row;
  width: 12.5vw;
  justify-content: space-between;
}

.btn_aplicar{
  background-color: #575757;
  color: white;
  padding: 6px;
  border-radius: 1rem;
  border: 0px;
}

.btn_borrar{
  background-color: #575757;
  color: white;
  padding: 6px;
  border-radius: 1rem;
  border: 0px
}

.btn_aplicar:hover, .btn_borrar:hover{
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.gray_text {
  color: gray;
}