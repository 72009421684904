.collapsible {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  width: 34vw;
  background-color: #50546f;
  color: white;
  font-size: 16px;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  justify-content: space-between;
}

.arrowClose{
  font-size: 16px;
  font-weight: bold;
  transform: rotate(90deg) scale(1,1.5);
  right: 1vw;
}

.arrowOpen{
  font-size: 16px;
  font-weight: bold;
  transform: rotate(-90deg) scale(1,1.5);
  right: 1vw;
}

.dosPartes {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-bottom: 1vh;
}

.subtitulo {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 0.5rem;
  max-width: 40vw;
}

.actionBox {
  width: 15vw;
}

.boton {
  margin-top: 2vh;
  margin-bottom: 5vh;
  line-height: 5vh;
  height: 5vh;
  width: 10vw;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #2bc26e;
}

.boton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  cursor: pointer;
}

.saved {
  display: flex;
  flex-direction: row;
}

.saveTrue {
  margin-left: 5px;
  color: #2bc26e;
  font-weight: bold;
}

.saveFalse {
  margin-left: 5px;
  color: #ff636e;
  margin-left: 15%;
  font-weight: bold;
}


.error {
  border: 2px solid red;
}

.subtitulo_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mensaje_error_datos {
  font-size: 12px;
  color: red;
}

.subtitulo_div p {
  padding: 0;
  margin: 0;
}

.boton_desactivado {
  opacity: 0.5;
  margin-top: 2vh;
  margin-bottom: 5vh;
  line-height: 5vh;
  height: 5vh;
  width: 10vw;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #2bc26e;
  cursor: not-allowed;
  pointer-events: none;
}

.div_datos_parte {
  display: flex;
  flex-direction: column;
}

.mas {
  background-color: #2bc26e;
  height: 4vh;
  width: 4vh;
  line-height: 4vh;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
}

.mas:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.menos {
  background-color: #ff636e;
  height: 4vh;
  width: 4vh;
  line-height: 4vh;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
}

.menos:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.telefonoFlex{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 2vw;
  margin-bottom: 1vh;
}

.phoneBox {
  width: 15vw;
  margin-bottom: 1vh;
}

.input_cursiva::placeholder {
  font-style: italic;
}