.mainContainer {
  background-color: #f2f3f4;
  padding-bottom: 1rem;
}

.infoInicial {
  padding-left: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f2f3f4;
  border-radius: 3px;
}

.infoInicial_columna2 {
  text-align: right;
  padding-right: 10rem;
  text-align-last: justify;
}

.etiquetaCaso {
  background-color: black;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  padding: 5px 16px;
  text-align: center;
  border-radius: 12px;
}

.etiquetaFechas {
  background: linear-gradient(to right, #fc881a, #ff1267);
  border: none;
  color: white;
  padding: 4px 12px;

  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  transition: all 0.3s ease;
}

.etiqueta_titulo {
  font-weight: bold;
}

.encabezado {
  padding-top: 1rem;
  font-size: 24px;
  font-weight: bolder;
  padding-left: 5rem;
  padding-bottom: 1rem;
  background-color: #4d4d4d;
  color: white;
  margin-bottom: 1rem;
}

.dosPartes {
  display: flex;
  flex-direction: row;
  gap: 15rem;
}

.checkWithOption {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.contenido_seguimiento {
  padding: 0 5rem;
}

.titulos_div {
  font-size: 18px;
  font-weight: bold;
}

.saveIcon {
  cursor: pointer;
  text-align: center;
  font-size: 2vw;
  font-weight: bolder;
  line-height: 4vw;
  position: fixed;
  right: 6vw;
  top: 12vh;
  height: 4vw;
  color: white;
}

.save:hover,
.clipIcon:hover {
  font-size: 2.5vw;
}

.saveIcon:hover {
  height: 4.25vw;
}

.attachIcon {
  cursor: pointer;
  text-align: center;
  font-size: 2vw;
  font-weight: bolder;
  line-height: 4vw;
  position: fixed;
  right: 6vw;
  top: 22vh;
  height: 4vw;
  border-radius: 50%;
}

.attachIcon:hover {
  height: 4.25vw;
}

.arrowIcon {
  height: 1rem;
  margin-left: 0.3rem;
  margin-top: 3px;
}

.blackClip {
  height: 1rem;
}

.acuerdoPartes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24rem;
  background-color: white;
  margin-bottom: 1rem;
}

.download {
  background-color: #2075ff;
  height: 2.1rem;
  margin-left: auto;
}

.acuerdoPartes:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.fecha_acuerdo {
  display: flex;
  justify-content: right;
  gap: 2rem;
  align-items: center;
}

.div_btn_guardar {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.div_btn_paso_a_cierre {
  display: flex;
  justify-content: center;
}

.boton_seguimiento {
  background-color: #4d4d4d;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 8px 12px;
  width: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.boton_seguimiento:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.div_accion_seguimiento {
  background-color: #4d4d4d;
  display: inline-block;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 16px;
  color: white;
  margin-right: 1rem;
}

.guardar_accion_div {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.guardar_accion_btn {
  background-color: #2196f3;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 8px 12px;
  width: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.guardar_accion_btn:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.fileInput,
.fileInput1 {
  display: none;
}

.fileInputLabel {
  display: inline-block;
  padding: 8px 16px;
  background-color: #2196f3;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.fileInputLabel:hover {
  background-color: #0e91d0;
}

.fileName {
  margin-top: 8px;
  margin-left: 4px;
}

.div_inputs_accion {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 0.75rem;
}

.boton_agregar_accion {
  background-color: #e3e3e3;
  border: none;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  padding: 8px 12px;
  width: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.boton_agregar_accion:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.div_boton_agregar_accion {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .div_acciones_mediador{
  display: flex;
  justify-content: center;
  align-items: center;
} */

.div_cantidad_dias {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.check {
  height: 1.5rem;
  width: 1.5rem;
}

.check_btn {
  background-color: #e3e3e3;
  border: none;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  padding: 2px 2px;
  width: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.check_btn:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

/* CSS MODAL GUARDADO */

.guardarModalContainer {
  position: fixed;
  background-color: #f6eeda;
  height: auto;
  width: 40vw;
  top: 50%;
  z-index: 1001;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
}

.encabezadoGuardar {
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 16px;
  font-weight: bolder;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  text-align: center;
  padding-bottom: 1rem;
  background-color: #4d4d4d;
  color: white;
  margin-bottom: 1rem;
}

.botones_modales_verificacion {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
}

.enunciado_modal {
  padding: 0rem 2rem;
  font-size: 14px;
  font-weight: bold;
}

.btn_seguir_en_form {
  margin-top: 2vh;
  /* margin-bottom: 5vh; */
  line-height: 5vh;
  height: 5vh;
  width: 15vw;
  border: none;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #eaaa0a;
}

.btn_seguir_en_form:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.btn_cerrar_form {
  margin-top: 2vh;
  margin-bottom: 5vh;
  line-height: 5vh;
  height: 5vh;
  border: none;
  width: 15vw;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #ff5565;
}

.btn_cerrar_form:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.btn_descarga_drawer {
  background-color: #0e91d0;
  border: none;
  border-radius: 12px;
  padding: 8px;
  color: white;
}

.btn_descarga_drawer:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.div_drawer_subir {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.fileName {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 4px;
}

.subtitulo {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 0.5rem;
  max-width: 40vw;
}

.div_subir_nuevos_documentos {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 1rem;
}

.nuevos_documentos {
  overflow-y: auto;
  max-height: 250px;
}

.div_subir_nuevos_documentos {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 1rem;
}

.nuevos_documentos {
  overflow-y: auto;
  max-height: 180px;
}

.columna_subir_nuevo {
  display: flex;
  flex-direction: column;
  width: 12rem;
  justify-content: center;
  margin: 0 auto;
}

.subir_nuevo_btn {
  background-color: #20c997;
  border: none;
  border-radius: 5px;
  padding: 8px;
  color: white;
  margin-top: 8px;
  font-weight: bold;
}

.subir_nuevo_btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.textAreaDiv {
  overflow-y: auto;
}

.btn_guardar_en_form {
  margin-top: 2vh;
  /* margin-bottom: 5vh; */
  line-height: 5vh;
  height: auto;
  width: 15vw;
  border: none;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #2075ff;
}

.btn_guardar_en_form:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}