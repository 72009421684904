.collapsible {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  width: 70vw;
  margin-left: 5vw;
  background-color: #50546f;
  color: white;
  font-size: 16px;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  justify-content: space-between;
  flex-direction: row;
}

.dateArrow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 10vw;
}

.arrowClose {
  color: #f2f2f2;
  font-size: 16px;
  font-weight: bold;
  transform: rotate(90deg) scale(1, 1.5);
  right: 1vw;
}

.arrowOpen {
  color: #f2f2f2;
  font-size: 16px;
  font-weight: bold;
  transform: rotate(-90deg) scale(1, 1.5);
  right: 1vw;
}

.subtitulo {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 0.5rem;
  max-width: 40vw;
}

.actionBox {
  width: 15vw;
}

.boton {
  margin-top: 2vh;
  margin-bottom: 5vh;
  line-height: 5vh;
  height: 5vh;
  width: 10vw;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #2bc26e;
}

.boton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  cursor: pointer;
}

.textArea {
  width: 60vw;
}

.sesion{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
 margin-bottom: 1rem;
  margin-left: 5vw;
  background-color: #E7EAEE;
  margin-right: 4vw;
    padding-left: 3rem;
    padding-bottom: 1rem;
}

.dosPartes {
  width: 60vw;
  
  display: flex;
  flex-direction: row;
  gap: 15rem;
  margin-bottom: 1vh;
}

.guardado_btn_div{
  display: flex;
  justify-content: left;
  align-items: center;
}

.guardado_btn{
  background-color: #4d4d4d;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 8px 12px;
  width: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.guardado_btn:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.fileInput {
  display: none;
}

.fileInputLabel {
  display: inline-block;
  padding: 8px 16px;
  background-color: #2196F3;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.fileInputLabel:hover {
  background-color: #0e91d0;
}

.fileName{
  margin-top: 8px;
  margin-left: 4px;
}

.saved {
  display: flex;
  flex-direction: row;
}

.saveTrue {
  margin-left: 5px;
  color: #2bc26e;
  font-weight: bold;
}

.saveFalse {
  margin-left: 5px;
  color: #ff636e;
  font-weight: bold;
}

.guardado_btn_desactivado{
  background-color: #4d4d4d;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 8px 12px;
  width: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  pointer-events: none;
}

.error{
  border: 2px solid red;
}

.subtitulo_div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mensaje_error_datos{
  font-size: 12px;
  color: red;
}

.subtitulo_div p{
  padding: 0;
  margin: 0;
}