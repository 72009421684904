.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(8px);
  z-index: 5;
  height: 100vh;
  width: 100vw;
}

.historicos_main {
  cursor: initial;
  position: fixed;
  left: 20vw;
  top: 12.5vh;
  height: 85vh;
  width: 60vw;
  border-radius: 12px;
  z-index: 10;
  background-color: #f2f2f2;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tabContainer{
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;  
}

.finalStrokeTop{
  width: 11.3vw;
  border-bottom: 1px solid black;
}

.tab{
  padding: 0.5rem;
  border: 1px solid black;
  display: inline-block;
  border-radius: 8px 8px 0 0;
  height: 5vh;
  width: 15vw;
  cursor: pointer;
}

.tab:hover{
  height: 6vh;
}

.tab_activa{
  padding: 0.5rem;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  background-color: #F9F9F9;
  font-weight: bold;
  display: inline-block;
  border-radius: 8px 8px 0 0;
  height: 7vh;
  width: 15vw;
  margin-top: 1rem;
}

.cerrar_btn_historicos{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  color: black; /* Color del texto del botón */
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cerrar_btn_historicos:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.historicos_cabecera{
  background-color: white;
  border-top-left-radius: 2vh;
  border-top-right-radius: 2vh;
  position: fixed;
  left: 20vw;
  width: 60vw;
  top:5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 7.5vh;
  padding: 0.5rem;
}

.folder{
  height: 4vw;
  stroke-width: 5;
}

.title{
  font-size: large;
  margin-left: 1vw;
}

.loader{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 85vh;
  width: 60vw;
}