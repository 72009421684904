.convocatoriaVictima_card {
  background-color: white;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 3px;
}

.convocatoriaVictima_card_row1,
.convocatoriaVictima_card_row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dosPartes {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.etiquetas_card {
  background-color: #ebeced;
  padding: 0.25rem;
  max-width: 12rem;
  border-radius: 3px;
}

.etiquetaFechas {
  background: linear-gradient(to right, #fc881a, #ff1267);
  border: none;
  color: white;
  padding: 4px 12px;

  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  transition: all 0.3s ease;
}

.dosPartes {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.checkWithOption {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.saveTrue{
  margin-left: 5px;
  color: #2bc26e;
  font-weight: bold;
}

.saveFalse{
  margin-left: 0px;
  color: #ff636e;
  font-weight: bold;
}

.subtitulo {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 0.5rem;
  max-width: 40vw;
}

.mainComponente{
  margin: 1rem 5rem;
}

.selectOne {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.subtitulo {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 0.5rem;
  max-width: 40vw;
}

.mainComponente{
  margin: 1rem 5rem;
  background-color: #E7EAEE;
  padding: 1rem;
  border-radius: 12px;
}

.fileInput {
  display: none;
}

.fileInputLabel {
  display: inline-block;
  padding: 8px 16px;
  background-color: #2196F3;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.fileInputLabel:hover {
  background-color: #0e91d0;
}

.fileName{
  margin-top: 8px;
  margin-left: 4px;
}

.guardado_btn_div{
  display: flex;
  justify-content: left;
  align-items: center;
}

.guardado_btn{
  background-color: #4d4d4d;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 8px 12px;
  width: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.guardado_btn:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.actionBox {
  width: 10vw;
}

.textarea {
  width: 35vw;
}

.guardado_btn_desactivado{
  background-color: #4d4d4d;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 8px 12px;
  width: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  pointer-events: none;
}

.error{
  border: 2px solid red;
}

.subtitulo_div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mensaje_error_datos{
  font-size: 12px;
  color: red;
}

.subtitulo_div p{
  padding: 0;
  margin: 0;
}