.mainContainer{
  display: flex;
  flex-direction: row;
  width: 18.5vw;
  justify-content: space-between;
}

.option{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.checkBad:checked:after{
  width: 12px;
  height: 12px;
  border-radius: 12px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #FE6169;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.checkNeutral:checked:after{
  width: 12px;
  height: 12px;
  border-radius: 12px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #FEB803;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.checkGood:checked:after{
  width: 12px;
  height: 12px;
  border-radius: 12px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #2EB980;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
