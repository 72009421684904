.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  height: 80vh;
  margin-top: 10vh;
  box-shadow: 3px 3px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 1vw;
}

.logo {
  height: 25vh;
}

.iniciarSesion{
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 2vh;
}

.inputBox{
  margin-bottom: 2vh;
}

.hintText{
  font-weight: bold;
  margin-bottom: 1vh;
}

.inputField{
  height: 2rem;
  width: 25vw;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}

.button{
  width: 10vw;
  height: 5vh;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  font-size: 13px;
}

.button:hover{
  font-size: 14px;
  box-shadow: 1.5px 1.5px 2px 2px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.logosContainer{
  margin-top: 3vh;
  display: flex;
  width: 50vw;
  flex-direction: row;
  justify-content: space-around;
}

.logoFooter{
  width: 15vw;
  text-align: center;
}

.textoLogo{
  display: flex;
  height: 15vh;
  flex-direction: column;
  justify-content: center;
}

.footer{
  position: absolute;
  text-align: center;
  font-size: 12px;
  width: 35vw;
  bottom: 12.5vh
}
