.boton_cierre_caso_penal {
  background-color: #4d4d4d;
  color: white;
  border: none;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 3px;
}

.boton_cierre_caso_penal:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.boton_cierre_caso_penal_desactivado {
  background-color: #4d4d4d;
  color: white;
  border: none;
  cursor: not-allowed;
  padding: 0.4rem;
  opacity: 0.9;
  border-radius: 3px;
}

.botones_modales_verificacion {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
}

.btn_modal_cerrar_caso {
  margin-top: 2vh;
  /* margin-bottom: 5vh; */
  line-height: 5vh;
  height: auto;
  width: 15vw;
  border: none;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #a544d9;
}

.btn_modal_cerrar_caso:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.btn_modal_seguir_editando {
  margin-top: 2vh;
  /* margin-bottom: 5vh; */
  line-height: 5vh;
  height: auto;
  width: 15vw;
  border: none;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #2075ff;
}

.btn_modal_seguir_editando:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.checkWithOption {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.cierre_penal_div {
  background-color: #f9f9f9;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  padding-top: 3vh;
  padding-left: 5vw;
  border-radius: 0 8px 0 0;
  width: 57vw;
  margin-right: 2vw;
  padding-bottom: 1rem;
}

.encabezado_modal {
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 16px;
  font-weight: bolder;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  text-align: center;
  padding-bottom: 1rem;
  background-color: #4d4d4d;
  color: white;
  margin-bottom: 1rem;
}

.enunciado_modal {
  padding: 0rem 2rem;
  font-size: 14px;
  font-weight: bold;
}

.modal_container {
  position: fixed;
  background-color: #ede6f7;
  height: auto;
  width: 40vw;
  top: 50%;
  z-index: 1001;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding-bottom: 1rem;
}

.titulos_div {
  font-size: 18px;
  font-weight: bold;
}
